import { useState } from "react";
import SearchArea from "./SearchArea";
import { Link, useNavigate } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/outline";
import { Link as Scroll } from "react-scroll";
import { BASE_URL, State } from "../constants";

import topImg from "../img/images/bg_mask.png";
import whiteLineLeft from "../img/images/line-try-left.png";
import whiteLineRight from "../img/images/line-try-right.png";
import line from "../img/images/line.png";
import arrow from "../img/images/about-icon-arrow.png";
import arrowGray from "../img/images/icon-arrow-gray.png";

import campaign_tokyo from "../img/images/top_campaign_tokyo.png";

import "../css/topContents.css";
import "../css/common.css";

export default function TopContents() {
  const [show, setShow] = useState(true);
  const [expand, setExpand] = useState<State>(State.DEFAULT);

  let navigate = useNavigate();

  let onSubmit = (searchParams: any, free: boolean) => {
    localStorage.removeItem("order");
    navigate({
      //pathname: free ? "/orderFree/select" : "/order/select",
      pathname: "/orderFree/select",
      search: searchParams.toString(),
    });
  };

  const content1 = {
    list: [
      {
        id: 1,
        text: "人気の5エリア間の発着なら、項目を選ぶだけで旅程が完成!",
      },
      {
        id: 2,
        text: "お好きな発着地&こだわり条件を指定して、らくらく旅行相談!",
      },
      {
        id: 3,
        text: "専門スタッフがお客様のご希望内容から最適なプランをご案内!",
      },
      {
        id: 4,
        text: "ご当地でのお食事相談も、旅行のプロにお任せください!",
      },
    ],
  };

  const content2imgs = {
    imgs: [
      { id: "Sapporo", img: require("../img/topSapporo.jpg") },
      { id: "Tokyo", img: require("../img/topTokyo.jpg") },
      { id: "Osaka", img: require("../img/topOsaka.jpg") },
      { id: "Fukuoka", img: require("../img/topFukuoka.jpg") },
      { id: "Okinawa", img: require("../img/topOkinawa.jpg") },
    ],
  };

  const content3 = {
    list: [
      {
        id: 1,
        text: "「遠方の友人の家に泊まるので、航空券のみを最安値で案内してほしい」",
      },
      {
        id: 2,
        text: "「記念日を素敵な宿で過ごしたい」",
      },
      {
        id: 3,
        text: "「宿の近くで、地元ならではの美味しい夕飯を食べたい」",
      },
    ],
  };

  const content4imgs = {
    imgs: [
      { id: "suitcase", img: require("../img/images/about2-icon1.png") },
      { id: "stuff", img: require("../img/images/about2-icon2.png") },
      { id: "meal", img: require("../img/images/about2-icon3.png") },
    ],
  };

  const goodValuePackImgs = {
    imgs: [
      {
        id: "g_value_air",
        img: require("../img/images/button_sp_air.png"),
        url: `${BASE_URL}special-pack/air_haneda.html`,
        alt: "羽田空港発の新千歳空港・伊丹空港・福岡空港・那覇空港行きのお得な航空券はこちら！"
      },
      {
        id: "g_value_shinkansen",
        img: require("../img/images/button_sp_shinkansen.png"),
        url: `${BASE_URL}special-pack/shinkansen_home.html`,
        alt: "東京発の京都・大阪・神戸・会津若松・山形・仙台・天童・名古屋・富山・金沢・軽井沢・長野行きのお得な新幹線きっぷはこちら！"
      },
    ],
  };

  return (
    <>
      <div className="w-full sm:px-0 text-color">
        <div className="bg-amber-50">
          <div className="flex">
            <img
              src={topImg}
              className="w-full h-[135px] object-cover z-0 brightness-110 relative"
            />
            <div className="scrollBtn">
              <Scroll to="contents" smooth={true}>
                <div className="flex flex-row justify-center items-center text-color font-semibold text-lg p-2 w-72">
                  <div className="w-4/5 flex justify-center">
                    どんなサービス？
                  </div>
                  <img src={arrow} className="scrollArrow" />
                </div>
              </Scroll>
            </div>
            {/* text-shadow 横・縦・ぼかし・影の色 */}
            <div className="subTitle subTitle1">
              いまなら相談無料！まずは一度お試しください
            </div>
            <div className="subTitle subTitle2">
              出張・観光に便利な
              <br className="flex md:hidden" />
              パック旅行をかんたん作成
            </div>
          </div>

          {/* <div className="w-full my-2 flex justify-center px-2 md:px-0">
            <a href={`${BASE_URL}html/campaign_tokyo.html?travel_top`}>
              <img
                className="md:w-[685px]"
                src={campaign_tokyo}
                alt="東京都在住者限定！もっとTokyo！最大25,000円お得になる宿泊割引キャンペーン！"
              />
            </a>
          </div> */}

          <div className="abroadLink">
            <a href="/html/abroad.html">
                <span>NEW</span>
                <span>海外旅行プランのお問い合わせはこちら &rarr;</span>
            </a>
          </div>

          <div id="search" className="pt-5 pb-10 bg-amber-50">
            <div className="max-w-5xl mx-auto">
              <SearchArea onSubmit={onSubmit} />
            </div>
          </div>

          <div className="spLinkBtn">
            {goodValuePackImgs.imgs.map((item) => (
                <a href={item.url}>
                    <img
                    key={item.id}
                    src={item.img}
                    alt={item.alt}
                    />
                </a>
            ))}
          </div>

          <div
            id="contents"
            className="bg-gradient-to-b from-yellow to-orange px-2 pb-16"
          >
            <div className="max-w-5xl mx-auto text-color flex flex-col items-center">
              <div className="space-y-5 mt-35p">
                <div className="contentBlock">
                  <div className="contentTitle text-center">
                    「ジョルダンMaaSパッケージ」とは
                  </div>

                  <img src={line} className="mx-auto" />

                  <div className="max-w-3xl mx-auto flex justify-center">
                    <ul className="space-y-2.5">
                      {content1.list.map((item) => (
                        <li
                          key={item.id}
                          className="flex flex-row items-center font-semibold list-none text-base sm:text-lg space-x-2"
                        >
                          <CheckIcon className="checkIcon" />

                          <div>{item.text}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* <div className="text-xs sm:text-sm flex justify-end">
                    (&#x203B;1)札幌・東京・大阪・福岡・那覇の5エリア
                  </div> */}
                </div>

                <div className="contentBlock">
                  <div className="contentTitle">
                    人気の5エリア間の発着なら、項目を選ぶだけで旅程が完成!
                  </div>
                  <div className="contentSub">
                    旅行先として大人気の5エリア【東京】【大阪】【福岡】【札幌】【那覇】を出発地と到着地に指定すると(&#x203B;1)、ご希望の条件に合わせた旅行パッケージを自動で作成します!
                    <br />
                    自動作成したパッケージは、編集画面から再度カスタマイズも可能なので、「これだ!」という条件がお決まりになったら、「この内容で申し込む」ボタンからお問い合わせください。専門スタッフがお客様のご希望内容から最適な旅行プランをご案内します(&#x203B;2)。
                  </div>
                  <div className="flex flex-row justify-evenly">
                    {content2imgs.imgs.map((item) => (
                      <img
                        key={item.id}
                        src={item.img}
                        alt={item.id}
                        className="w-14 md:w-36 h-14 md:h-36 rounded-full"
                      />
                    ))}
                  </div>
                </div>

                <div className="contentBlock">
                  <div className="contentTitle">
                    お好きな発着地&amp;こだわり条件を指定して、らくらく旅行相談!
                  </div>
                  <div className="contentSub">
                    旅行に関するご相談もお気軽に!ご希望内容をもとに専門スタッフが直接ご案内します。
                  </div>
                  <div className="max-w-3xl mx-auto bg-amber-50 py-5 px-5">
                    <div className="font-semibold mb-2">
                      こんな旅行のご相談も承ります!
                    </div>
                    <div className="flex flex-col space-y-2">
                      <ul className="list-disc list-inside">
                        {content3.list.map((item) => (
                          <li key={item.id}>{item.text}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="contentBlock">
                  <div className="contentTitle">
                    専門スタッフがお客様のご希望内容から最適なプランをご案内！
                  </div>
                  <div className="contentSub">
                    申し込みいただいた内容をもとに、専門スタッフが対応いたします。
                    <br />
                    さらにお客様のご要望を直接伺うことで、ニーズ・ご予算にあった旅行プランを調整させていただきます。旅行会社窓口でのサービスを、ご自宅からお電話・メールにて体験いただける「ジョルダンMaaSパッケージ」を、ぜひご活用ください。
                  </div>

                  <div className="flex flex-row justify-evenly items-center">
                    {content4imgs.imgs.map((item) => (
                      <img
                        key={item.id}
                        src={item.img}
                        alt={item.id}
                        className="w-14 md:w-auto h-auto"
                      />
                    ))}
                  </div>

                  <div className="contentSub">
                    お電話からのご相談は
                    <a
                      href="tel:0353694056"
                      className="text-cyan-600 hover:opacity-80 font-semibold underline"
                    >
                      03-5369-4056
                    </a>
                    から承ります。お気軽にお問い合わせください！(&#x203B;3)
                  </div>
                </div>

                <div className="contentBlock">
                  <div className="contentTitle">
                    ご当地でのお食事相談も、旅行のプロにお任せください!
                  </div>
                  <div className="contentSub">
                    旅先でのお食事に関する情報も、専門スタッフがご案内します。ぜひ「STEP5
                    夕食」で「相談する」を選択のうえお申込みください。宿泊先付近の美味しいもの情報をお届けします!
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center space-x-2 md:space-x-4 my-10">
                <img src={whiteLineLeft} className="h-8 md:h-12 w-auto" />
                <div className="font-semibold text-white flex items-center flex-col">
                  <div className="text-base md:text-lg">
                    まずはお試しください
                  </div>
                  <div className="text-lg md:text-2xl">
                    ジョルダンMaaSパッケージを作成
                  </div>
                </div>
                <img src={whiteLineRight} className="h-8 md:h-12 w-auto" />
              </div>

              <div className="scrollBtnBottom">
                <Scroll to="search" smooth={true}>
                  <div className="flex flex-row justify-center items-center text-color font-semibold text-lg p-2 w-72">
                    <div className="w-4/5 flex justify-center">
                      希望条件を選ぶ
                    </div>
                    <img src={arrowGray} className="scrollArrowGray" />
                  </div>
                </Scroll>
              </div>

              <div className="w-full">
                (&#x203B;1)5エリア間発着の飛行機でのご移動を指定した場合に限ります。
                <br />
                (&#x203B;2)お問い合わせ時点ではご予約は確定いたしません。ご予約は出発日の3日前の
                17:00まで可能です。
                <br />
                (&#x203B;3)受付時間は平日9時30分～12時、13時～17時となります。土・日・祝祭日は休業となります。
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
